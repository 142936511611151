import styled from "styled-components";
export var Container = styled.div.withConfig({
  displayName: "indexstyle__Container",
  componentId: "sc-e4t2xx-0"
})(["padding-top:1.5rem;background-color:", ";height:100vh;width:100%;overflow-y:auto;padding-right:80px;padding-left:80px;padding-bottom:64px;@media (max-width:1024px){padding-right:32px;padding-left:32px;padding-bottom:48px;}@media (max-width:375px){padding-right:16px;padding-left:16px;}"], function (_ref) {
  var _theme$layout;
  var theme = _ref.theme;
  return ((_theme$layout = theme.layout) === null || _theme$layout === void 0 ? void 0 : _theme$layout.backGroundColor) || "#ffffff";
});
export var WideContainer = styled(Container).withConfig({
  displayName: "indexstyle__WideContainer",
  componentId: "sc-e4t2xx-1"
})(["padding-right:16px;padding-left:24px;@media (max-width:1024px){padding-right:8px;padding-left:12px;}@media (max-width:375px){padding-right:4px;padding-left:8px;}"]);
export var NoPaddingContainer = styled(Container).withConfig({
  displayName: "indexstyle__NoPaddingContainer",
  componentId: "sc-e4t2xx-2"
})(["padding:0;"]);
export var Header = styled.div.withConfig({
  displayName: "indexstyle__Header",
  componentId: "sc-e4t2xx-3"
})(["padding-top:48px;padding-bottom:48px;display:flex;justify-content:space-between;gap:24px;flex-wrap:wrap;align-items:flex-end;flex-direction:row;", "{padding:16px;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:8px;}"], function (_ref2) {
  var theme = _ref2.theme;
  return [theme.breakpoints.down("xs")];
});
export var TitleAdornment = styled.div.withConfig({
  displayName: "indexstyle__TitleAdornment",
  componentId: "sc-e4t2xx-4"
})(["margin-left:auto;align-self:center;"]);
export var TitleContainer = styled.div.withConfig({
  displayName: "indexstyle__TitleContainer",
  componentId: "sc-e4t2xx-5"
})([""]);
export var ChildrenWrapper = styled.div.withConfig({
  displayName: "indexstyle__ChildrenWrapper",
  componentId: "sc-e4t2xx-6"
})(["height:100%;display:flex;flex-direction:column;"]);